import React from "react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import Layout from "../components/Layout";
import Retriever from "../components/Retriever";

const Index = (props: any) => {
  return (
    <>
      <Layout>
        <div>
          <h2>Retrieve a Secure One Time Message</h2>
          <p>
            The message will automatically self-destruct when read or once expired.
          </p>
          <Retriever url={props?.url} />
        </div>
      </Layout>
    </>
  );
}

export default Index
