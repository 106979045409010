import React from "react";
import { Link } from "gatsby";

import style from "./SendMessage/style.module.css";
import Loading from "./Icons/Loading";

class Retriever extends React.Component<any> {
  state = {
    error: ``,
    message: ``,
    loading: true,
  }

  componentDidMount() {
    this.retrieveData();
  }

  async retrieveData() {
    if (this?.props?.url) {
      const ENDPOINT_READ = "/api/otm/ReadMessage";
      const createMessageRequest = await fetch(ENDPOINT_READ, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: this.props.url,
        })
      });
      const createMessageResponse = await createMessageRequest.json();

      if (!createMessageRequest.ok) {
        this.setState({
          loading: false,
          error: createMessageResponse.error,
        });
      } else {
        this.setState({
          loading: false,
          message: createMessageResponse.data,
        });
      }
    } else {
      this.setState({ loading: false, error: "No message found." })
    }
  }

  render() {
    return (
      <>
        {!this.state.error && !this.state.message ? (
          <div className="showLoading">
            <Loading type="blue" />
          </div>
        ) : (
          <div className={style.messageBox}>
            {this.state.error ? (
              <div className={style.errorMessage}>{this.state.error}</div>
            ) : null}
            {this.state.message ? (
              <>
                <h3>Private Message</h3>
                <textarea
                  className="message"
                  defaultValue={this.state.message}
                  disabled={true}
                ></textarea>
              </>
            ) : (
              ""
            )}
          </div>
        )}
        <Link className={style.link} to="/">
          Send a message
        </Link>
      </>
    )
  }
}
export default Retriever
